import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Portfolio() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the portfolio.</div>;
    }

    const holdings = [
        { equities: "AST SpaceMobile", ticker: "ASTS", dividendYield: "--", costBasis: "$9,670", reinvestedDividends: "--", cashDividends: "--", marketValue: "$58,335", portfolioPercent: "14.0%", totalgainloss: "503%" },
        { equities: "SoFi Technologies", ticker: "SOFI", dividendYield: "--", costBasis: "$39,997", reinvestedDividends: "--", cashDividends: "--", marketValue: "$57,626", portfolioPercent: "13.8%", totalgainloss: "44%" },
        { equities: "AGNC Investment Corp", ticker: "AGNC", dividendYield: "13.8%", costBasis: "$51,644", reinvestedDividends: "$9,070", cashDividends: "$360", marketValue: "$54,036", portfolioPercent: "12.9%", totalgainloss: "5%" },
        { equities: "Comtech Telecommunications Corp", ticker: "CMTL", dividendYield: "--", costBasis: "$43,955", reinvestedDividends: "--", cashDividends: "--", marketValue: "$59,520", portfolioPercent: "14.2%", totalgainloss: "35%" },
        { equities: "PubMatic", ticker: "PUBM", dividendYield: "--", costBasis: "$49,995", reinvestedDividends: "--", cashDividends: "--", marketValue: "$39,293", portfolioPercent: "9.4%", totalgainloss: "(21%)" },        
        { equities: "Pinterest", ticker: "PINS", dividendYield: "--", costBasis: "$25,096", reinvestedDividends: "--", cashDividends: "--", marketValue: "$36,400", portfolioPercent: "8.7%", totalgainloss: "45%" },
        { equities: "Equities Total", ticker: "--", dividendYield: "3.6%", costBasis: "$220,356", reinvestedDividends: "$9,070", cashDividends: "$360", marketValue: "$305,209", portfolioPercent: "73.0%", totalgainloss: "39%" },
        { equities: "Cash", ticker: "--", dividendYield: "--", costBasis: "--", reinvestedDividends: "--", cashDividends: "--", marketValue: "$112,772", portfolioPercent: "27.0%", totalgainloss: "--" },
        { equities: "Portfolio Value", ticker: "--", dividendYield: "2.5%", costBasis: "$332,000", reinvestedDividends: "--", cashDividends: "--", marketValue: "$417,981", portfolioPercent: "100.0%", totalgainloss: "26%" }
    ];


    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Portfolio Holdings (as of 10/31/24)</h3>
                <DropdownMenu />  
            </div>    
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Equities</th>
                        <th>Ticker</th>
                        <th>Dividend Yield</th>
                        <th>Cost Basis</th>
                        <th>Market Value</th>
                        <th>% of Portfolio</th>
                        <th>Total % gain/loss</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.map((holding, index) => (
                        <tr key={index}>
                            <td>{holding.equities}</td>
                            <td>{holding.ticker}</td>
                            <td>{holding.dividendYield}</td>
                            <td>{holding.costBasis}</td>
                            <td>{holding.marketValue}</td>
                            <td>{holding.portfolioPercent}</td>
                            <td>{holding.totalgainloss}</td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    );
}

export default Portfolio;
